(function () {
  var $ = require('./vendor/jquery');
  var $window = $(window);
  var $navigation = $('.js-navigation');
  var $headerHeadline = $('.js-header-headline');
  var headerHeadlineOffset;

  var navigationHeight = $navigation.height();

  if ($headerHeadline.length) {
    headerHeadlineOffset = $headerHeadline.offset().top;
  }

  var onScroll = function () {
    var wScroll = $window.scrollTop();

    if (wScroll < headerHeadlineOffset - navigationHeight - 50) {
      $navigation.removeClass('global-navigation-is-scrolled');
    } else if (wScroll >= headerHeadlineOffset - navigationHeight - 50) {
      $navigation.addClass('global-navigation-is-scrolled');
    }
  };

  $(document).ready(function () {
    if ($headerHeadline.length) {
      $window.scroll(onScroll);
      onScroll();
    }

    require('./link-scroll')($, $('.js-navigation-link'), $('body'));

    if ($('#js-standort-gilching').length && $('#js-standort-radeberg').length && $('#js-standort-traunreut').length) {
      require('./osm')();
    }

    require('./menu')($);

    require('./belt')($);
  });
})();
