module.exports = function () {
  var L = require('./vendor/leaflet');

  L.Icon.Default.imagePath = '/images/leaflet';

  var coords = {
    gilching: [48.10795, 11.28952],
    radeberg: [51.114872, 13.910699],
    traunreut: [47.963564, 12.593450]
  };

  var mapGilching = L.map('js-standort-gilching').setView(coords.gilching, 12);
  var mapRadeberg = L.map('js-standort-radeberg').setView(coords.radeberg, 12);
  var mapTraunreut = L.map('js-standort-traunreut').setView(coords.traunreut, 12);

  L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(mapGilching);
  L.marker(coords.gilching).addTo(mapGilching);

  L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(mapRadeberg);
  L.marker(coords.radeberg).addTo(mapRadeberg);

  L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(mapTraunreut);
  L.marker(coords.traunreut).addTo(mapTraunreut);
};
