module.exports = function ($, $links, $body) {
  var sectionScroll = function (e) {
    var $this = $(this);
    var href = $this.attr('href').split('#')[1];
    var $target = $(href);
    var $mobileNav = $('.global-navigation-mobile');
    var mobileNavOpenClassName = 'global-navigation-mobile-open';

    if ($target.length > 0) {
      e.preventDefault();

      history.pushState('', '', href);
      $mobileNav.removeClass(mobileNavOpenClassName);
      $body.animate({
        scrollTop: $target.offset().top
      }, 400);
    }
  };

  $links.on('click', sectionScroll);
};
