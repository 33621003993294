module.exports = function ($) {
  var classNameActiveItem = 'js-active-belt-item';
  var $beltItems = $('.u-belt-item');
  var $itemSelectors = $('.js-belt-selector-item');
  var $beltControlNext = $('.js-belt-control-next');
  var $beltControlPrev = $('.js-belt-control-prev');
  var $belt = $('.u-belt');

  // var $this = $(this);
  // var href = $(this).attr('href').split("#")[1];

  $beltItems.eq(0).addClass(classNameActiveItem);
  $itemSelectors.eq(0).addClass(classNameActiveItem);

  var beltControl = function (e) {
    e.preventDefault();
    var $this = $(this);
    var curActiveItem = $belt.find('.' + classNameActiveItem);
    var position = $belt.children().index(curActiveItem);
    var jobNum = $beltItems.length;

    if ($this.hasClass('js-belt-control-next')) {
      if (position < jobNum - 1) {
        $('.' + classNameActiveItem).removeClass(classNameActiveItem).next().addClass(classNameActiveItem);
      } else {
        $beltItems.removeClass(classNameActiveItem);
        $beltItems.eq(0).addClass(classNameActiveItem);
        $itemSelectors.removeClass(classNameActiveItem);
        $itemSelectors.eq(0).addClass(classNameActiveItem);
      }
    } else {
      if (position === 0) {
        $beltItems.removeClass(classNameActiveItem);
        $beltItems.eq(jobNum - 1).addClass(classNameActiveItem);
        $itemSelectors.removeClass(classNameActiveItem);
        $itemSelectors.eq(jobNum - 1).addClass(classNameActiveItem);
      } else {
        $('.' + classNameActiveItem).removeClass(classNameActiveItem).prev().addClass(classNameActiveItem);
      }
    }
  };

  var beltIconControl = function (e) {
    e.preventDefault();
    var $this = $(this);
    var $siblings = $this.parent().children();
    var position = $siblings.index($this);

    $beltItems.removeClass(classNameActiveItem);
    $beltItems.eq(position).addClass(classNameActiveItem);
    $siblings.removeClass(classNameActiveItem);
    $this.addClass(classNameActiveItem);
  };

  $beltControlNext.click(beltControl);
  $beltControlPrev.click(beltControl);
  $itemSelectors.click(beltIconControl);
};
