module.exports = function ($) {
  var $mobileNav = $('.global-navigation-mobile');
  var $navOpen = $('.js-mobile-navigation-open');
  var $navClose = $('.js-mobile-navigation-close');
  var navOpenClassName = 'global-navigation-mobile-open';

  var onNavOpen = function (e) {
    e.preventDefault();

    $mobileNav.addClass(navOpenClassName);
  };

  var onNavClose = function (e) {
    e.preventDefault();

    $mobileNav.removeClass(navOpenClassName);
  };

  $navOpen.on('click', onNavOpen);
  $navClose.on('click', onNavClose);
};
